import React from 'react'
import { Link } from 'gatsby'
import { AwesomeButton } from 'react-awesome-button'
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-awesome-button/dist/styles.css'

import Layout from '../../components/Layout'
import Head from '../../components/Head'
import { meContents } from '../../contents'
import './styles.scss'

const Skills = () => {
  return (
    <div className="d-flex flex-wrap skills">
      {meContents.skills.map((skill, i) => (
        <div className="mr-2 mb-2" key={i}>
          <AwesomeButton
            style={{ fontWeight: 700 }}
            className={`skill-item ${skill.replace(/\s+/g, '-').toLowerCase()}`}
            type="primary"
            ripple
          >
            {skill}
          </AwesomeButton>
        </div>
      ))}
    </div>
  )
}

const ActionButtons = () => {
  return (
    <div className="d-flex callToActions">
      <Link to="/portfolio" className="view-projects">
        <AwesomeButton style={{ fontWeight: 500 }} type="secondary" ripple>
          <div className="d-flex align-items-center">
            <i className="fa fa-eye mr-2"></i>
            <span>View Portfolio</span>
          </div>
        </AwesomeButton>
      </Link>
      <Link to="/contact" className="reach-me">
        <AwesomeButton style={{ fontWeight: 500 }} type="primary" ripple>
          <div className="d-flex align-items-center">
            <i className="fas fa-paper-plane mr-2"></i>
            <span>Reach Me</span>
          </div>
        </AwesomeButton>
      </Link>
    </div>
  )
}

const AboutPage = () => {
  return (
    <Layout isNavVisible={true}>
      <Head title="About Me" />
      <div
        id="aboutMePage"
        className="d-flex flex-column flex-grow-1 justify-content-center"
      >
        <div className="row no-gutters align-items-center align-items-lg-start animate fade-in">
          <div className="col-12 order-lg-2 col-lg-4 justify-content-lg-end justify-content-xl-center col-xl-5 ml-xl-auto d-flex">
            <div
              style={{ backgroundImage: "url('/profile-picture.jpg" }}
              className="mb-3 self-pik"
            ></div>
          </div>
          <div className="col-12 order-lg-1 col-lg-8 col-xl-7 d-flex flex-column">
            <div className="mb-3">
              <h1 className="self-intro d-none d-sm-block">
                Hello! I'm Kim Lian Lopez.
              </h1>
              <h1 className="self-intro d-sm-none">I'm Kim Lian Lopez.</h1>
              <h2 className="self-tagline mb-4">
                A Full Stack Web Developer & Designer
              </h2>
              <Skills />
            </div>
            <p className="self-description text-left">
              I'm a passionate and experienced web developer with a knack for
              building amazing web applications. Naturally curious and
              enthusiastic, I love to learn new technology and development
              stacks every now and then. <br /> <br />
              From basic and simple websites to complex web applications, I'm
              here to help you create and build them to reality.
            </p>
            <ActionButtons />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
